<template>
        <main-template>
            <template v-slot:headerLottieDark>
                <dotlottie-player :src="getDarkLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:headerLottieLight>
                <dotlottie-player :src="getLightLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:header>Procure</template>
            <template v-slot="slotProps">
            <fetch-siren-root :linkName='linkName' :link="link">
                <div slot-scope="{ response }" class="flex flex-grow overflow-hidden">
                    <div class="flex w-full overflow-hidden">
                        <div class="p-2 h-full">
                            <procurement-navigation :links="slotProps.links"></procurement-navigation>
                        </div>
                        <div class="flex w-full h-full p-2 overflow-hidden">
                            <div class="rounded-xl bg-v3-white-100 w-full h-full margin-10 dark:bg-v3-gray-900 overflow-y-auto">
                                <loading v-if="!exportOrdersAction && !exportInvoicesAction && !clientExportInvoicesAction && !clientExportOrdersAction"></loading>
                                <template v-else>
                                    <div class="p-2 h-full">
                                        <div class="grid grid-cols-2 gap-2 h-full">
                                            <div class="bg-v3-gray-50 bg-opacity-10 rounded-xl h-full p-2" v-if="exportOrdersAction">
                                                <h2 class="flex justify-center">Export Orders</h2>
                                                <p class="my-2 text-sm italic"></p>
                                                <div class="flex justify-center">
                                                    <order-reports-form :action="exportOrdersAction"></order-reports-form>
                                                </div>
                                            </div>
                                            <div  class="bg-v3-gray-50 bg-opacity-10 rounded-xl h-full p-2" v-else-if="clientExportOrdersAction">
                                                <h2 class="flex justify-center">Export Order</h2>
                                                <p class="my-2 text-sm italic"></p>
                                                <div class="flex justify-center">
                                                    <order-reports-form :action="clientExportOrdersAction"></order-reports-form>
                                                </div>
                                            </div>
                                            <div class="bg-v3-gray-50 bg-opacity-10 rounded-xl h-full p-2" v-if="exportInvoicesAction || reimportAction">
                                                <h2 class="flex justify-center">Export Invoices</h2>
                                                <p class="my-2 text-sm italic"></p>
                                                <div class="flex flex-col">
                                                    <invoice-export-form @success="showReimportForm = true" v-if="!showReimportForm && exportInvoicesAction" :action="exportInvoicesAction"></invoice-export-form>
                                                    <reimport-form v-if="showReimportForm && reimportAction" :action="reimportAction"></reimport-form>
                                                    <div v-if="!showReimportForm" class="text-center pt-4 text-xs hover:underline cursor-pointer" @click="showReimportForm = true">Already done this? - Skip to reimport</div>
                                                    <div v-else class="text-center pt-4 text-xs hover:underline cursor-pointer" @click="showReimportForm = false">Back to export</div>
                                                </div>
                                            </div>
                                            <div class="bg-v3-gray-50 bg-opacity-10 rounded-xl h-full p-2" v-else-if="clientExportInvoicesAction || clientReimportAction">
                                                <h2 class="flex justify-center">Export Invoices</h2>
                                                <p class="my-2 text-sm italic"></p>
                                                <div class="flex flex-col">
                                                    <invoice-export-form @success="clientShowReimportAction = true" v-if="!clientShowReimportAction && clientExportInvoicesAction" :action="clientExportInvoicesAction"></invoice-export-form>
                                                    <reimport-form v-if="clientShowReimportAction && clientReimportAction" :action="clientReimportAction"></reimport-form>
                                                    <div v-if="!clientShowReimportAction" class="text-center pt-4 text-xs hover:underline cursor-pointer" @click="clientShowReimportAction = true">Already done this? - Skip to reimport</div>
                                                    <div v-else class="text-center pt-4 text-xs hover:underline cursor-pointer" @click="clientShowReimportAction = false">Back to export</div>
                                                </div>
                                            </div>
                                            <div class="bg-v3-gray-50 bg-opacity-10 rounded-xl h-full p-2" v-if="batchReconcileAction">
                                                <h2 class="flex justify-center">Batch Reconcile</h2>
                                                <p class="my-2 text-sm italic"></p>
                                                <div class="flex flex-col">
                                                    <batch-reconcile-form class="flex justify-center"
                                                        :action="batchReconcileAction"
                                                        action-key="batch-reconcile"
                                                        :on-success-path="$route.path"
                                                        :redirect-on-success="false"
                                                    ></batch-reconcile-form>
                                                </div>
                                            </div>
                                            <div class="bg-v3-gray-50 bg-opacity-10 rounded-xl h-full p-2" v-if="batchReconcileAction">
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </fetch-siren-root>
            </template>
        </main-template>
</template>

<script>
import FetchSirenRoot from '@/components/FetchSirenRoot';
import InvoiceExportForm from "@/v2/components/forms/InvoiceExportForm.vue";
import Loading from '@/components/Loading';
import MainTemplate from '@/v3/templates/MainTemplate';
import {mapGetters} from "vuex";
import OrderReportsForm from "@/v3/components/forms/OrderReportsForm.vue";
import ProcurementNavigation from "@/v3/components/navigation/ProcurementNavigation.vue";
import ReimportForm from "@/v2/components/forms/ReimportForm.vue";
import Siren from "super-siren";
import BatchReconcileForm from "@/v3/components/forms/BatchReconcileForm.vue";

export default {
    components: {
        BatchReconcileForm,
        ProcurementNavigation,
        InvoiceExportForm, ReimportForm,
        FetchSirenRoot,
        Loading,
        MainTemplate,
        OrderReportsForm
    },
    data() {
        return {
            slotProps: null,
            users: null,
            date: null,
            exportOrdersAction: null,
            exportInvoicesAction: null,
            reimportAction: null,
            showReimportForm: false,
            clientExportOrdersAction: null,
            clientExportInvoicesAction: null,
            clientReimportAction: null,
            clientShowReimportAction: false,
            batchReconcileAction: null
        }
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
        }),
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },
    },
    mounted() {
        let follow = Siren.Helper.follow;
        Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
        Siren.get(process.env.VUE_APP_API_URL + "/api/")
            .then(follow("reports"))
            .then(res => {
                let siren = res.body;
                this.exportOrdersAction = siren.actions.filter(action => action.name === 'get-report').first();
                this.exportInvoicesAction = siren.actions.filter(action => action.name === 'export-invoices').get('export-invoices');
                this.reimportAction = siren.actions.filter(action => action.name === 'reimport-with-sage-numbers').get('reimport-with-sage-numbers');
                this.batchReconcileAction = siren.actions.filter(action => action.name === 'batch-reconcile').get('batch-reconcile');

            }, this);
        Siren.get(process.env.VUE_APP_API_URL + "/api/")
            .then(follow("client-reports"))
            .then(res => {
                let siren = res.body;
                this.clientExportOrdersAction = siren.actions.filter(action => action.name === 'get-report').first();
                this.clientExportInvoicesAction = siren.actions.filter(action => action.name === 'export-invoices').get('export-invoices');
                this.clientReimportAction = siren.actions.filter(action => action.name === 'reimport-with-sage-numbers').get('reimport-with-sage-numbers');

            }, this);


    },
    methods: {
        getDarkLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=procure_dark"
        },
        getLightLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=procure_light"
        }
    }
}
</script>

